body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 300;
}

body,
.page {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  color: $dark-green;
  text-decoration: none;

  &:hover,
  &.text-white:hover {
    color: $mid-green;
    text-decoration: none;
  }
}

.btn {
  background-color: $dark-green;
  border: 2px solid $dark-green;

  &.focus,
  &:focus,
  &:hover {
    color: white !important;
    background-color: #277b0c;

    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    border: 2px solid #277b0c;
  }

  i {
    margin-left: 10px;
  }
}

.text-dark-green {
  color: $dark-green;
}

.large-text {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

* {
  scroll-behavior: smooth;
}

// Coped from Bootstrap 4
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* clients page */

#clients-page {
  .card a {
    color: black;
  }

  .card-title {
    font-weight: 500;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.8rem;
  }

  .helper-icon {
    color: $bright-green;
    font-size: 40px;
    margin-right: 15px;
  }
}

/* Contact Page */

.webp #get-in-touch {
  background: center / cover no-repeat
    url(../images/misc/pexels-robert-schrader-5805272.webp);
}

.no-webp #get-in-touch {
  background: center / cover no-repeat
    url(../images/misc/pexels-robert-schrader-5805272.jpg);
}

/* Newsletter */

#mc-embedded-subscribe-form {
  color: red;
  font-weight: bold;

  .success-message {
    color: white !important;
    text-align: center;
  }

  .error-message {
    color: red !important;
  }
}
