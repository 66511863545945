#nav {
  margin-bottom: 60px;

  &.navbar.bg-white .logo-white,
  &.navbar.bg-transparent .logo-black,
  &.navbar.bg-transparent.scroll .logo-white {
    display: none;
  }

  &.navbar.bg-transparent .logo-white,
  &.navbar.bg-transparent.scroll .logo-black {
    display: flex;
  }

  &.navbar {
    padding: 5px 0;
    background-color: #fff;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-transition: all 350ms ease-in-out;
    -moz-transition: all 350ms ease-in-out;
    -o-transition: all 350ms ease-in-out;
    -ms-transition: all 350ms ease-in-out;
    transition: all 350ms ease-in-out;

    &.bg-transparent {
      .logo-black {
        display: none;
      }

      .navbar-nav > li > a {
        color: #fff;
      }

      &.scroll .logo-black {
        display: flex;
      }

      &.scroll .navbar-nav > li > a {
        color: $dark-green;
      }
    }

    &.scroll {
      padding: 0px;
      background-color: #fff !important;
      box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
    }
  }

  .nav-tabs > li > a:hover {
    color: white;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background-color: $dark-green;
    border-color: $dark-green;
    color: #fff;
    border-bottom: 1px solid $dark-green;
  }

  .bg-white .navbar-nav > li > a:hover,
  .bg-transparent.scroll .navbar-nav > li > a:hover {
    color: $mid-green;
    text-decoration: none;
  }

  .bg-white .navbar-nav > li > a {
    color: $dark-green;
  }

  .bg-transparent .navbar-nav > li > a:hover {
    color: $dark-green;
    text-decoration: none;
  }

  .logo {
    height: 30px;
    &:focus-visible {
      outline: none;
    }
  }

  .navbar-expand-md > .container {
    margin-left: auto;
  }

  .navbar-nav > li > a:hover,
  .bg-transparent.scroll .navbar-nav > li > a:hover {
    color: $mid-green;
  }

  .navbar-nav > li > a {
    color: #333;
    font-size: 0.9rem;
    line-height: 3.5rem;
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: 0 8px;

    &:focus {
      background-color: transparent;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

@media (min-width: 576px) {
  #nav {
    .logo {
      height: 40px;
    }
  }
}

@media only screen and (max-width: 767px) {
  #nav.navbar {
    &.bg-transparent {
      padding: 0;
      background-color: #fff !important;

      .logo-white {
        display: none;
      }

      .logo-black {
        display: block;
      }

      .navbar-nav > li > a {
        color: $deep-green !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md > .container {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  #nav .navbar-nav > li > a {
    padding: 0 12px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0;
  }
}
