#footer {
  font-size: 1.125rem;

  .footer-top {
    background-color: #292929;

    a {
      color: white !important;

      &a:hover {
        color: $mid-green;
        text-decoration: underline;
      }

      &.social-media-link {
        color: #ccc;
        width: 30px;
        height: 30px;
        font-size: 19px;
        line-height: 30px !important;
        text-align: center;
        margin-right: 4px;
      }
    }

    .ti-email:hover,
    .ti-mobile:hover,
    .ti-home:hover {
      color: $mid-green;
    }

    // Col 1
    .footer-about {
      line-height: 1.875rem;

      p {
        margin-left: 30px;
      }

      .about-item {
        padding: 5px 0;

        .contact-address-line {
          padding: 5px 0;
          margin-bottom: 0;
        }
      }

      .contact-datum {
        margin-left: 10px;
      }

      .footer-icons {
        margin-top: 5px;
        margin-left: 25px;

        li {
          margin-right: 30px;

          i:hover {
            color: $mid-green;
          }
        }
      }
    }

    // Col 2
    .useful-links {
      display: flex;
      justify-content: center;

      .column {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      li {
        width: auto !important;
        display: inherit !important;
        vertical-align: inherit;
        clear: none !important;
        padding: 4px 0;
      }
    }

    // Col 3 (Newsletter)

    #mc-embedded-subscribe {
      background-color: lightgrey;
      color: black;
      font-size: 1.25rem;

      &:hover {
        background-color: $bright-green;
        color: white;
      }
    }
  }

  .footer-bottom {
    background-color: #222;

    .footer-copyright {
      color: #333;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #ddd;
    }
  }
}
