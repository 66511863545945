/* Styling for Pages (non homepage pages) */

.header-info-section {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
  background-image: url(../images/header/forrest-2.jpg);

  h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header-info-section {
    margin-top: 60px;
  }
  .header-info-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .header-info-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 640px) {
  .header-info-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .header-info-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 320px) {
  .header-info-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
