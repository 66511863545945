#explanation {
  picture {
    max-width: 100%;
  }

  @media only screen and (min-width: 768px) {
    picture {
      max-width: 450px;
    }
  }
}
