.webp #intro .hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */

  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("/assets/images/misc/pexels-noelle-otto-906097.webp");
}

.no-webp #intro .hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("/assets/images/misc/pexels-noelle-otto-906097.jpg");
}

#intro {
  .hero-image {
    height: 100vh;
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
}
