.webp #plan {
  background: center / cover no-repeat url(../images/misc/green-banner.webp);
}

.no-webp #plan {
  background: center / cover no-repeat url(../images/misc/green-banner.jpeg);
}

#plan {
  .circle {
    border: 0.1em solid lightgrey;
    border-radius: 100%;
    height: 4em;
    width: 4em;
    text-align: center;

    .num {
      font-family: "roboto";
      color: $deep-green;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
