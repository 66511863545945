/* roboto-300 latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* roboto-300italic latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300italic;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/roboto-v20-latin-300italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* roboto-700 latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* roboto-900 latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-900.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* poppins-300 latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/poppins-v15-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v15-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v15-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v15-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v15-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v15-latin-300.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-regular latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/poppins-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v15-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v15-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v15-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v15-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/poppins-v15-latin-regular.svg#Poppins") format("svg"); /* Legacy iOS */
}

/* poppins-500 latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/poppins-v15-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v15-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v15-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v15-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v15-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v15-latin-500.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-600 latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/poppins-v15-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v15-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v15-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v15-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v15-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v15-latin-600.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-700 latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  font-display: swap;
  src: url("../fonts/poppins-v15-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/poppins-v15-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/poppins-v15-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/poppins-v15-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/poppins-v15-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/poppins-v15-latin-700.svg#Poppins")
      format("svg"); /* Legacy iOS */
}
