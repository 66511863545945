#services {
  .card {
    .card-body {
      height: 100%;
      min-height: 10rem;
      max-height: 10rem;
      // min-height: 13.125rem;
      // max-height: 13.125rem;

      background-color: #efefee !important;
    }

    .service {
      ul {
        text-align: left;
        font-size: 1.125rem;

        li {
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 1.3em;
          line-height: 1.5rem;

          &:before {
            content: "\e64c";
            font-family: "themify";
            display: inline-block;
            color: $dark-green;
            margin-left: -1.3em; /* same as padding-left set on li */
            width: 1.3em; /* same as padding-left set on li */
          }
        }
      }

      .price {
        font-size: 1.625rem;
        line-height: 2.5rem;
      }
    }
  }
}
