#blog {
  z-index: 1;

  input[type="text"] {
    outline: none;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
  }

  .results {
    position: relative;

    ul.result-list {
      z-index: 1000;
      position: absolute;
      top: 0;

      li:hover {
        background-color: #ccc;
      }
    }
  }

  .card {
    font-size: 1.125rem;
    line-height: 1.625rem;
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

    &:hover {
      transform: scale(1.05);
    }

    .card-footer {
      font-size: 1rem;
    }
  }
}

#blog,
#blog-post {
  p {
    color: #444;
  }
}

#blog-post {
  h2 {
    margin-bottom: 1.25rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    padding-left: 25px;
    margin-bottom: 10px;
  }

  .share {
    .share-cta {
      vertical-align: text-top;
      font-size: 2rem;
      line-height: 3rem;
    }

    ul {
      list-style: none;
    }

    .share-social-icons {
      padding-left: 0 !important;
      height: 2.188rem;

      a.share-ico {
        width: 5.625rem;
        height: 2.188rem;
      }

      a.share-ico.ico-facebook {
        background-color: #3b5998;
        border-color: #3b5998;
      }

      a.share-ico.ico-twitter {
        background-color: #00a9ed;
        border-color: #00a9ed;
      }

      a.share-ico.ico-linkedin {
        background-color: #015886;
        border-color: #015886;
      }

      a.share-ico.ico-envelope {
        background-color: #999;
        border-color: #999;
      }
    }
  }
}
